import {useDecision} from '@optimizely/react-sdk';
import {isEmpty, snakeCase} from 'lodash';
import {set} from 'lodash/fp';
import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {Button, Icon} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {EVENT_NAMES, FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import BriefFieldManager from '@/views/campaigns/brief/brief-field-manager';
import CollapsibleSection from '@/views/campaigns/brief/components/campaign-brief/components/collapsible-section';
import {SECTIONS} from '@/views/campaigns/brief/components/campaign-brief/sections';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {
  CAMPAIGN_STATE,
  IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY,
  IMMEDIATE_SAVE_CAMPAIGN_IF_CHANGED_DELAY,
} from '@/views/campaigns/brief/constants';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import useSubscriptionLevers from '@/hooks/use-subscription-levers';
import {modalSubscriptionActions} from '@/stores/modalSubscriptionStore';
import styles from './CampaignBrief.module.scss';
import {CampaignBriefProps} from './CampaignBriefProps';
import UpgradeBanner from './components/upgrade-banner/UpgradeBanner';

const TRANSLATION_PREFIX = 'components.campaign-brief';

function AutoSaveLabel() {
  return (
    <div className={styles.autoSaveLabel}>
      <Icon size="medium" appearance="disabled" name="Actions-LooksOn" />
      <label>
        {translate(`${TRANSLATION_PREFIX}.brief-automatically-saved`)}
      </label>
    </div>
  );
}

export type AnalyticsContext = {
  campaignId: string;
  fieldName?: string;
  fieldValue?: string;
};

function CampaignBrief(props: CampaignBriefProps) {
  const {
    testID = 'campaign-brief',
    campaign,
    editable,
    isLoading,
    onSubmitForm,
    saveCampaign,
    originalCampaign,
    onCampaignBriefFormUpdate,
    campaignBriefRef,
    campaignBriefContainerRef,
    viewPresentationId,
  } = props;

  const isMobile = useIsMobile();
  const [formIsChanged, setFormIsChanged] = useState(false);
  const imageUrlChangedRef = useRef(false);
  const [campaignBriefForm, setCampaignBriefForm] = useState(campaign);
  const [originalCampaignBriefForm, setOriginalCampaignBriefForm] = useState(
    originalCampaign || campaign
  );
  const [showFormErrors, setShowFormErrors] = useState(false);
  const saveCampaignTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});
  const levers = useSubscriptionLevers();
  const [pricingUpdatesDecision] = useDecision('pricing-updates', {
    autoUpdate: true,
  });

  const activeCampaignCountMaxed = levers?.isMaxed('ActiveCampaignCount');

  useEffect(() => {
    campaignBriefRef.current = {
      campaignBriefForm,
    };
  }, [campaignBriefForm]);

  const sectionRefs = useMemo(
    () =>
      SECTIONS.reduce((acc: {[key: string]: any}, {id}) => {
        acc[id] = createRef();
        return acc;
      }, {}),
    []
  );

  const briefFieldManager = useMemo(
    () => new BriefFieldManager(campaign),
    [
      campaignBriefForm.loaded,
      campaignBriefForm.isPlaceholder,
      campaignBriefForm.isProcessing,
      campaignBriefForm.version,
    ]
  );

  useEffect(() => {
    briefFieldManager.fields.subscriptionType.value =
      subscription.subscriptionType;
  }, [subscription]);

  useEffect(() => {
    briefFieldManager.fields.imageUrl.value = campaign.imageUrl;
  }, [campaign.imageUrl]);

  const briefGenerationCompleted = useMemo(() => {
    return (
      editable &&
      !isLoading &&
      !campaignBriefForm.isProcessing &&
      !campaignBriefForm.isPlaceholder
    );
  }, [
    editable,
    isLoading,
    campaignBriefForm.isProcessing,
    campaignBriefForm.isPlaceholder,
  ]);

  const campaignBriefFormIsValid = useMemo(() => {
    if (briefGenerationCompleted) {
      const formFieldsErrors = briefFieldManager.fieldsErrors;
      return Object.keys(formFieldsErrors).every(
        (key) => Object.keys(formFieldsErrors[key]).length === 0
      );
    }
    return false;
  }, [campaignBriefForm, briefGenerationCompleted]);

  useEffect(() => {
    if (campaignBriefFormIsValid) {
      setShowFormErrors(false);
    }
  }, [campaignBriefFormIsValid]);

  useEffect(() => {
    setCampaignBriefForm(campaign);
    if (originalCampaign?.isProcessing && !campaign?.isProcessing) {
      setOriginalCampaignBriefForm(campaign);
    } else {
      setOriginalCampaignBriefForm(originalCampaign || campaign);
    }
  }, [campaign]);

  useEffect(() => {
    setOriginalCampaignBriefForm(originalCampaign || campaign);
  }, [originalCampaign]);

  const updateBriefForm = (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number,
    triggerShowFormErrors?: boolean
  ) => {
    // use lodash/fp set function to do a nested update to the campaignBriefForm state
    const newCampaignBriefFormState = set(path, value, campaignBriefForm);
    setCampaignBriefForm(newCampaignBriefFormState);
    onCampaignBriefFormUpdate?.(newCampaignBriefFormState);
    setFormIsChanged(saveCampaignDelay === undefined);
    if (briefFieldKey) {
      briefFieldManager.fields[briefFieldKey].value = value;
    }
    if (path === 'imageUrl') {
      imageUrlChangedRef.current = true;
    }

    clearTimeout(saveCampaignTimeoutRef.current);
    if (typeof saveCampaignDelay === 'number') {
      if (saveCampaignDelay === IMMEDIATE_SAVE_CAMPAIGN_IF_CHANGED_DELAY) {
        onSaveCampaign({
          campaignId: campaign.id,
          fieldName: briefFieldKey,
          fieldValue: value,
        });
      } else if (saveCampaignDelay === IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY) {
        onSaveCampaign(
          {
            campaignId: campaign.id,
            fieldName: briefFieldKey,
            fieldValue: value,
          },
          true
        );
      } else {
        saveCampaignTimeoutRef.current = setTimeout(() => {
          onSaveCampaign(
            {
              campaignId: campaign.id,
              fieldName: briefFieldKey,
              fieldValue: value,
            },
            true
          );
        }, saveCampaignDelay);
      }
    }

    if (triggerShowFormErrors) {
      const foundSectionErrors = Object.keys(
        briefFieldManager.fieldsErrors
      ).find(
        (sectionKey) =>
          !isEmpty(briefFieldManager.fieldsErrors[sectionKey][path])
      );
      if (foundSectionErrors) {
        setShowFormErrors(true);
      }
    }
  };

  const onSaveCampaign = async (
    analyticsContext?: AnalyticsContext,
    overrideFormIsChanged = false
  ) => {
    const serializedData = await briefFieldManager.getSerializedData();
    const sendAnalytics = !!analyticsContext;

    if (!imageUrlChangedRef.current) {
      serializedData.image = null;
    }

    if (formIsChanged || overrideFormIsChanged) {
      const interactionId = uuid();
      const processId = uuid();

      if (sendAnalytics) {
        const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
          FLOW_NAMES.CAMPAIGN_CREATION
        );

        AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_VIEW_INTERACTION, {
          flow_id: campaignCreationFlow.flow_id,
          interaction_type: 'update',
          interaction_name: 'brief_field_manually_updated',
          campaign_id: analyticsContext.campaignId,
          interaction_location: 'edit_brief_form',
          interaction_details: JSON.stringify({
            field_name: snakeCase(analyticsContext.fieldName),
            field_value: analyticsContext.fieldValue,
          }),
          interaction_id: interactionId,
          view_presentation_id: viewPresentationId,
        });

        AnalyticsService.dispatchEvent(
          EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_STARTED,
          {
            flow_id: campaignCreationFlow.flow_id,
            process_source: 'draft_review',
            process_details: JSON.stringify({
              field_name: snakeCase(analyticsContext.fieldName),
              field_value: analyticsContext.fieldValue,
            }),
            process_id: processId,
            interaction_id: interactionId,
          }
        );
      }
      await saveCampaign?.(serializedData, undefined, processId, sendAnalytics);
      setFormIsChanged(false);
      imageUrlChangedRef.current = false;
    }
  };

  function sendLaunchInteractionEvent(interactionDetails: {
    form_validation: string;
  }) {
    const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.CAMPAIGN_CREATION
    );

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_VIEW_INTERACTION, {
      flow_id: campaignCreationFlow.flow_id,
      interaction_type: 'click',
      interaction_name: 'form_launch_campaign_clicked',
      campaign_id: campaign.id,
      interaction_location: 'edit_brief_form',
      interaction_details: JSON.stringify(interactionDetails),
      interaction_id: uuid(),
      view_presentation_id: viewPresentationId,
    });
  }

  const eventData = {
    isNewFlowStarted: true,
    reason: 'ActiveCampaignCount',
  };

  const submit = async () => {
    if (activeCampaignCountMaxed) {
      modalSubscriptionActions.open({
        featureName: 'ActiveCampaignCount',
        eventData,
      });
      return;
    }

    if (!campaignBriefFormIsValid) {
      sendLaunchInteractionEvent({form_validation: 'invalid'});
      setShowFormErrors(true);
      setTimeout(() => {
        const firstSectionWithError = SECTIONS.find(
          ({id}) => !isEmpty(briefFieldManager.fieldsErrors[id])
        );
        if (firstSectionWithError) {
          const top =
            (sectionRefs?.[firstSectionWithError.id]?.current?.offsetTop || 0) -
            150;
          campaignBriefContainerRef.current?.scrollTo?.({
            top,
            left: 0,
            behavior: 'smooth',
          });
        }
      });
      return;
    }
    sendLaunchInteractionEvent({form_validation: 'valid'});

    if (showFormErrors) {
      setShowFormErrors(false);
    }

    const serializedData = await briefFieldManager.getSerializedData();

    if (!imageUrlChangedRef.current) {
      serializedData.image = null;
    }

    setOriginalCampaignBriefForm(campaignBriefForm);
    onSubmitForm?.(serializedData, serializedData);
    setFormIsChanged(false);
    imageUrlChangedRef.current = false;
  };

  return (
    <div className={styles.container} data-testid={testID}>
      <div className={styles.sectionsContainer} data-testid={testID}>
        {SECTIONS.map(({id, titleLocaleLabelKey, Component, initialIsOpen}) => {
          const sectionErrors = briefFieldManager.fieldsErrors[id] || {};
          const sectionHasErrors = Object.keys(sectionErrors).length > 0;

          return (
            <CollapsibleSection
              key={id}
              title={translate(titleLocaleLabelKey)}
              initialIsOpen={initialIsOpen}
              hasErrors={showFormErrors && sectionHasErrors}
            >
              <div
                className={styles.collapsibleSectionContainer}
                ref={sectionRefs[id]}
              >
                <Component
                  campaign={campaignBriefForm}
                  editable={briefGenerationCompleted}
                  updateBriefForm={updateBriefForm}
                  saveCampaign={onSaveCampaign}
                  setShowFormErrors={setShowFormErrors}
                  errors={showFormErrors ? sectionErrors : {}}
                  briefFieldManager={briefFieldManager}
                  viewPresentationId={viewPresentationId}
                />
              </div>
            </CollapsibleSection>
          );
        })}

        {campaign.state === CAMPAIGN_STATE.DRAFT && campaign.loaded ? (
          <AutoSaveLabel />
        ) : (
          <div />
        )}

        <div
          className={`${styles.buttonContainer} ${
            activeCampaignCountMaxed && styles.upgradeBannerContainer
          }`}
        >
          {activeCampaignCountMaxed && pricingUpdatesDecision.enabled ? (
            <UpgradeBanner
              testID="upgrade-banner"
              onButtonClick={() =>
                modalSubscriptionActions.open({
                  featureName: 'ActiveCampaignCount',
                  eventData,
                })
              }
            />
          ) : null}
          {campaign.isPlaceholder ? null : (
            <Button
              testID={`${CAMPAIGN_BRIEF_TEST_IDS.LAUNCH_CAMPAIGN_BUTTON}--${
                campaignBriefFormIsValid ? 'valid' : 'invalid'
              }`}
              disabled={
                isLoading ||
                campaign.state !== CAMPAIGN_STATE.DRAFT ||
                campaignBriefForm.isProcessing
              }
              appearance="neutral"
              mode="filled"
              size={isMobile || activeCampaignCountMaxed ? 'medium' : 'large'}
              onClick={submit}
              className={`${styles.button} ${
                activeCampaignCountMaxed && styles.upgradeBannerButton
              }`}
            >
              {translate(`${TRANSLATION_PREFIX}.submit-button`)}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CampaignBrief;
