import {OptimizelyProvider} from '@optimizely/react-sdk';
import {QueryClientProvider} from '@tanstack/react-query';
import {ApiProvider} from 'jsonapi-react';
import {capitalize} from 'lodash';
import React, {ReactNode} from 'react';
import {I18nextProvider} from 'react-i18next';
import {DesignSystemThemeProvider} from '@lightricks/react-design-system';
import optimizelyClient from '@/services/optimizely/Optimizely';
import {BannerProvider} from '@/contexts/BannerContext';
import {CampaignBriefHelperProvider} from '@/contexts/CampaignBriefHelperContext';
import {ContentGenerationStoreProvider} from '@/contexts/ContentGenerationStore';
import ContextProviderComposer from '@/contexts/ContextProviderComposer';
import {NewCampaignFormDataProvider} from '@/contexts/NewCampaignContext';
import i18n from './config/locales';
import jsonApiClient from './utils/jsonapiClient';
import reactQueryClient from './utils/reactQueryClient';

function AppProviders({children}: {children: ReactNode}) {
  return (
    <OptimizelyProvider optimizely={optimizelyClient} user={{id: 'anonymous'}}>
      <ApiProvider client={jsonApiClient}>
        <QueryClientProvider client={reactQueryClient}>
          <I18nextProvider i18n={i18n}>
            <DesignSystemThemeProvider theme="popularpayLight">
              <ContextProviderComposer
                contexts={[
                  BannerProvider,
                  ContentGenerationStoreProvider,

                  NewCampaignFormDataProvider,
                  CampaignBriefHelperProvider,
                ]}
              >
                {children}
              </ContextProviderComposer>
            </DesignSystemThemeProvider>
          </I18nextProvider>
        </QueryClientProvider>
      </ApiProvider>
    </OptimizelyProvider>
  );
}

export default AppProviders;
