import type {QueryFunction, QueryKey, QueryMeta} from '@tanstack/query-core';
import {useMemo} from 'react';
import {FiltersGroup} from '@/types/models/search-creators/filter';
import {
  FilterIds,
  FiltersGroupId,
} from '@/types/models/search-creators/filterId';
import {CreatorSearchResult} from '@/types/models/search-creators/searchCreators';
import {Paging} from '@/types/models/search-creators/searchCreatorsStore';
import {Sorting} from '@/types/models/search-creators/sorting';
import QueryContext from '@/api/QueryContext';
import searchCreatorsFetchers, {
  CreatorsSearchResponse,
} from '@/api/fetchers/creators/search/searchCreators';
import {
  mapToAppliedFilters,
  mapToMultiplatformFilter,
  mapToSorting,
} from '@/api/fetchers/creators/search/utils';
import useInfiniteReactQuery from '@/hooks/use-infinite-react-query';

const DEFAULT_CREATORS_SEARCH_RESPONSE: CreatorsSearchResponse = {
  searchSessionId: undefined,
  creators: [],
  loadedPages: 0,
  pagingParams: {
    currentPage: 0,
    totalPages: 0,
    totalResults: 0,
  },
};

const BASIC_QUERY_KEY = 'searchCreators';

export type SearchCreatorsQueryProps = {
  sessionId?: string;
  paging: Paging;
  searchQuery?: string;
  sorting?: Sorting;
  selectedBrandId: string;
  selectedCampaignId?: string;
  filterGroups: Record<FiltersGroupId, FiltersGroup<FilterIds>>;
  isRestoring: boolean;
  pageParam?: number;
  disabled?: boolean;
};

function isStartSearchSessionRequest({sessionId}: SearchCreatorsQueryProps) {
  return !sessionId;
}

export function mapStateToQueryKey(props: SearchCreatorsQueryProps): QueryKey {
  const {paging, searchQuery, sorting, filterGroups, selectedBrandId} = props;

  return [
    BASIC_QUERY_KEY,
    mapToSorting(sorting),
    mapToAppliedFilters(filterGroups),
    searchQuery,
    selectedBrandId,
    paging.itemsPerPage,
  ];
}

function mapStateToQueryFunction(
  props: SearchCreatorsQueryProps
): QueryFunction<CreatorsSearchResponse> {
  return isStartSearchSessionRequest(props)
    ? searchCreatorsFetchers.startSearchSession
    : searchCreatorsFetchers.changePage;
}

function mapStateToQueryMeta(props: SearchCreatorsQueryProps): QueryMeta {
  const {sessionId, paging, searchQuery, sorting, filterGroups} = props;
  if (isStartSearchSessionRequest(props)) {
    return {
      type: 'multiplatform',
      sort: mapToSorting(sorting),
      query: mapToMultiplatformFilter(searchQuery, filterGroups),
      itemsPerPage: paging.itemsPerPage,
    };
  }
  return {sessionId};
}

function useSearchCreatorsQuery(props: SearchCreatorsQueryProps) {
  const {disabled = false} = props;
  const response = useInfiniteReactQuery<CreatorsSearchResponse>({
    queryKey: mapStateToQueryKey(props),
    queryFn: (context: QueryContext) => mapStateToQueryFunction(props)(context),
    keepPreviousData: true, // don't go to 0 rows when refetching or paginating to next page
    enabled: !props.isRestoring && !disabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    meta: mapStateToQueryMeta(props),
    getNextPageParam: (lastPage: CreatorsSearchResponse) => {
      if (!props.sessionId) return undefined;
      const {pagingParams} = lastPage;
      return pagingParams.currentPage < pagingParams.totalPages
        ? pagingParams.currentPage + 1
        : undefined;
    },
  });

  const creatorsSearchResponse: CreatorsSearchResponse = useMemo(() => {
    const {pages = []} = response?.data || {pages: []};
    const [firstPageData] = pages;

    const responseData = firstPageData
      ? {
          searchSessionId: firstPageData.searchSessionId,
          creators: pages.flatMap(
            (pageData: {creators: CreatorSearchResult[]}) => pageData?.creators
          ),
          pagingParams: firstPageData.pagingParams,
          loadedPages: pages.length,
        }
      : null;
    return responseData || {...DEFAULT_CREATORS_SEARCH_RESPONSE};
  }, [response.data]);

  return {
    data: creatorsSearchResponse,
    isError: response.isError,
    isFetching: response.isFetching,
    isRefetching: response.isRefetching,
    isLoading: response.isLoading,
    isFetched: response.isFetched,
    refetch: response.refetch,
    fetchNextPage: response.fetchNextPage,
    hasNextPage: response.hasNextPage,
  };
}

export default useSearchCreatorsQuery;
