import {orderBy} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {Box, Stack, styled} from '@mui/material';
import {
  Button,
  Checkbox,
  designSystemToken,
  Headline,
  Icon,
  Input,
  Label,
} from '@lightricks/react-design-system';
import {raiseFlashMessageError} from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import ContentContainer from '@/views/creators/components/creator-action/action/ContentContainer';
import useAddToLists from '@/hooks/mutations/creators/use-add-to-lists';
import useCreateCreatorGroupLabel, {
  maxNameLength,
} from '@/hooks/mutations/use-create-creator-group-label';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useCreatorGroupsLabelsQuery from '@/hooks/queries/use-creator-groups-labels-query';
import useBrandId from '@/hooks/use-brand-id';
import Autocomplete, {Option} from '../../autocomplete/Autocomplete';
import {ActionProps} from './ActionProps';
import SubmitButtonContainer from './SubmitButtonContainer';

const checkboxCheckedSrc = '/assets/svg/checkbox-checked.svg';
const checkboxUncheckedSrc = '/assets/svg/checkbox-unchecked.svg';

const TRANSLATION_PREFIX =
  'views.creators.components.creator-action.add-to-my-creators';

const NewListButton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: 56 - 8, // remove vertical padding
  padding: '4px 16px',
  gap: 8,
  borderRadius: 8,
  marginTop: 16,
  cursor: 'pointer',
  backgroundColor: designSystemToken('semantic.bg.tertiary'),
}));

const NewListContent = styled('div')`
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel-root {
    padding: 12px 0 0 16px;
  }
  .MuiCheckbox-root {
    margin: 0;
  }
  .MuiFormHelperText-root {
    top: 90% !important;
  }
`;

const GroupHeader = styled('div')(({theme}) => ({
  position: 'sticky',
  top: '-10px',
  padding: '12px 16px',
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
}));

const GroupItems = styled('ul')({
  padding: 0,
});

function AddToMyCreators(props: ActionProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [isSharedList, setIsSharedList] = useState(false);
  const {creators, onSubmitSuccess, initState} = props;
  const brandId = useBrandId();
  const {currentBrand, brands, data} = useAgencyQuery({
    brandId,
  });

  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';
  const {labels} = useCreatorGroupsLabelsQuery({creatorGroupId});
  const addToLists = useAddToLists();

  const [addNewList, setAddNewList] = useState(!labels.length);

  const createCreatorGroupLabel = useCreateCreatorGroupLabel(creatorGroupId);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    initState ?? []
  );

  useEffect(() => {
    if (initState?.length === 1) {
      initState[0].disabled = true;
    }
    setSelectedOptions(initState ?? selectedOptions);
  }, [initState]);

  useEffect(() => {
    setSelectedOptions(
      selectedOptions.map((option) => ({
        ...option,
        disabled: selectedOptions.length === 1,
      }))
    );
  }, [selectedOptions]);

  const getGroupTitle = (brandName: string, organizationLevel: boolean) => {
    return `${brandName ? `${brandName} ` : ''}${translate(
      `${TRANSLATION_PREFIX}.group-title-${
        organizationLevel ? 'shared' : 'restricted'
      }`
    )}`;
  };

  const mappedOptions = useMemo(
    () =>
      /* Sorts groups so that organization level is first and list labels by ascending order */
      orderBy(
        labels.map((label) => ({
          id: label.id,
          label: label.name,
          disabled:
            label.disabled ||
            selectedOptions.length === 1 ||
            !!initState?.find(
              (option: Option) => option.id === label.id && option.disabled
            ),
          organizationLevel: !!label.organizationLevel,
          groupTitle: getGroupTitle(
            currentBrand.name,
            !!label.organizationLevel
          ),
        })),
        [(item) => Number(item.organizationLevel), 'label'],
        ['desc', 'asc']
      ),
    [labels, selectedOptions.length]
  );

  const hasSharedLabels = useMemo(
    () => mappedOptions.some((label) => label.organizationLevel),
    [mappedOptions]
  );

  const handleCreateNewList = async () => {
    if (creatorGroupId) {
      const {data: newLabelData} = await createCreatorGroupLabel.mutateAsync({
        creatorGroupId,
        name: newListName,
        organizationLevel: isSharedList,
      });
      if (newLabelData) {
        setSelectedOptions((currentSelectedOptions) => [
          ...currentSelectedOptions,
          {
            id: newLabelData.id,
            label: newLabelData.name,
            organizationLevel: newLabelData.organizationLevel,
            groupTitle: getGroupTitle(
              currentBrand.name,
              !!newLabelData.organizationLevel
            ),
          },
        ]);
        setNewListName('');
        setAddNewList(false);
      }
    }
  };

  const handleAddToList = async () => {
    const creatorIds = creators.map((creator) => creator.id);
    const labelIds = selectedOptions.map((option) => option.id);
    try {
      const result = await addToLists.mutateAsync({
        creatorIds,
        creatorGroupId,
        labelIds,
      });
      if (result) {
        onSubmitSuccess(selectedOptions);
      }
    } catch {
      raiseFlashMessageError();
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (addNewList) {
      await handleCreateNewList();
    } else {
      await handleAddToList();
    }
    setIsSubmitting(false);
  };

  const handleChange = (
    _: React.SyntheticEvent,
    value: Option[],
    action: string,
    details?: {option: Option}
  ) => {
    if (action === 'removeOption' && details?.option?.disabled) {
      return;
    }
    setSelectedOptions(value);
  };

  const renderHeader = () => {
    if (addNewList) {
      return null;
    }
    return (
      <NewListButton
        onClick={() => {
          setAddNewList(true);
        }}
      >
        <Icon appearance="neutral" size="medium" name="Actions-Add-Small1" />
        <Label size="lg">{translate(`${TRANSLATION_PREFIX}.new-list`)}</Label>
      </NewListButton>
    );
  };

  const renderContent = React.useCallback(() => {
    if (addNewList) {
      return (
        <NewListContent>
          <Input
            autoFocus
            value={newListName}
            onInputChange={(value: string) => setNewListName(value)}
            clearIcon={false}
            sx={{'.MuiInputBase-root': {minHeight: 56}}}
            validatorOptions={{
              onChangeText: (value: string) => setNewListName(value),
              validators: [
                {
                  validator: (val: string) => val.length < maxNameLength,
                  errorMessage: translate(
                    `${TRANSLATION_PREFIX}.new-list-name-validation`,
                    {count: maxNameLength}
                  ),
                },
              ],
            }}
          />
          <Checkbox
            label={translate(`${TRANSLATION_PREFIX}.shared-lists-checkbox`)}
            checked={isSharedList}
            onChange={() => setIsSharedList(!isSharedList)}
            customCheckedIcon={<img src={checkboxCheckedSrc} alt="" />}
            customUncheckedIcon={<img src={checkboxUncheckedSrc} alt="" />}
          />
        </NewListContent>
      );
    }
    return (
      <Autocomplete
        clearIcon={false}
        multiple
        placeholder={translate(`${TRANSLATION_PREFIX}.dropdown-placeholder`)}
        options={mappedOptions}
        selectedOptions={selectedOptions}
        onChange={handleChange}
        groupBy={
          hasSharedLabels
            ? (option: Option) => option.groupTitle as string
            : undefined
        }
        renderGroup={(params) => {
          // the "params" object does not contain data whether the group is restricted or shared (organizationLevel) so we need to resort to checking the group name
          const isRestrictedLevel =
            getGroupTitle(currentBrand.name, false) === params.group;
          return (
            <li key={params.key}>
              <GroupHeader>
                <Stack direction="row" gap={1} alignItems="center">
                  {isRestrictedLevel ? (
                    <Icon
                      appearance="neutral"
                      size="medium"
                      name="Actions-Lock-Line"
                    />
                  ) : null}
                  <Headline size="sm">{params.group}</Headline>
                </Stack>
              </GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          );
        }}
      />
    );
  }, [mappedOptions, selectedOptions, addNewList, newListName, isSharedList]);

  const isValid = useMemo(() => {
    return addNewList
      ? !newListName || newListName.length > maxNameLength
      : selectedOptions.length === 0;
  }, [addNewList, newListName, selectedOptions]);

  const renderFooter = () => {
    return (
      <Box display="flex" gap={1}>
        {addNewList && labels.length ? (
          <Button
            appearance="neutral"
            mode="plain"
            size="large"
            onClick={() => setAddNewList(false)}
          >
            {translate(`${TRANSLATION_PREFIX}.back-button`)}
          </Button>
        ) : null}
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          disabled={isValid || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          {addNewList
            ? translate(`${TRANSLATION_PREFIX}.submit-button-save`)
            : translate(`${TRANSLATION_PREFIX}.submit-button`)}
        </Button>
      </Box>
    );
  };

  return (
    <Stack>
      {renderHeader()}
      <ContentContainer>{renderContent()}</ContentContainer>
      <SubmitButtonContainer>{renderFooter()}</SubmitButtonContainer>
    </Stack>
  );
}

export default AddToMyCreators;
