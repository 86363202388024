import dayjs from 'dayjs';
import React, {useEffect, useMemo} from 'react';
import {Button, Headline, Icon} from '@lightricks/react-design-system';
import {
  screenNames,
  reasons,
  dismissedReasons,
  buttonNames,
} from '@/lib/delta/deltaConstants.js';
import raiseFlashMessage from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import {Plan, PaymentTypeOption} from '@/types/plan';
import LogoMarquee from '@/views/pricing/components/logo-marquee';
import PricingTier from '@/views/pricing/components/pricing-tier';
import {CURRENCY, TRIAL_TIER_NAME} from '@/views/pricing/constants';
import PoppaysLoader from '@/components/PoppaysLoader';
import Link from '@/components/link';
import handleContactSalesClick from '@/components/modal-subscription-wrapper/handleContactSalesClick';
import {
  handleFlowEndedEvent,
  handleScreenDismissedEvent,
  handleScreenPresentedEvent,
  handleSubscriptionButtonPressedEvent,
} from '@/components/modal-subscription/AnalyticsEventsHandler';
import {MenuPopularPaysLogo} from '@/components/unusual-icons';
import usePlansQuery from '@/hooks/queries/use-plans-query';
import useQuoteQuery from '@/hooks/queries/use-quote-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import useNavigation from '@/hooks/use-navigation';
import useModalSubscriptionStore, {
  modalSubscriptionActions,
  DEFAULT_PLAN_NAME,
} from '@/stores/modalSubscriptionStore';
import styles from './PricingPage.module.scss';

const TRANSLATION_PREFIX = 'components.pricing';

type PricingPageProps = {
  decisions: any;
};

const handleBackClick = () => {
  handleFlowEndedEvent({
    isFlowEnded: true,
    reason: reasons.SUBSCRIPTION_MODAL.ABANDONED,
    currentScreenName: screenNames.PRICING.PRICING_PAGE,
  });
  window.history.back();
};

function hideSubscriptionButton(
  paymentType: PaymentTypeOption,
  planId: string,
  activePlanId: string
) {
  if (!activePlanId) {
    return false;
  }
  return (
    paymentType === PaymentTypeOption.NOT_APPLICABLE ||
    paymentType === PaymentTypeOption.INVOICE ||
    planId === activePlanId
  );
}

function hideContactSalesButton(
  paymentType: PaymentTypeOption,
  planId: string,
  activePlanId: string
) {
  return (
    !activePlanId ||
    paymentType === PaymentTypeOption.NOT_APPLICABLE ||
    planId === activePlanId
  );
}
function convertDurationToDays(trialDuration: string) {
  if (!trialDuration) return 0;
  const date = dayjs.duration(trialDuration);
  return date.asDays();
}
function PricingPage(props: PricingPageProps) {
  const {decisions} = props;
  const brandId = useBrandId();
  const {navigate} = useNavigation();
  const isMobile = useIsMobile();
  const {subscription} = useSubscriptionQuery({brandId});
  const {plan: activePlan} = subscription;
  const {plansData} = usePlansQuery();

  const contactSalesRequestLoading = useModalSubscriptionStore(
    (store) => store.loading
  );

  const plansCollection = useMemo(() => {
    if (!plansData) return [];
    let sortedPlans = plansData.sort((a: Plan, b: Plan) =>
      a.cost && b.cost ? a.cost - b.cost : a.cost || b.cost
    );
    if (isMobile) {
      const promotedPlan = sortedPlans.find((plan: Plan) => plan.isPromoted);
      const plansWithoutPromoted = sortedPlans.filter(
        (plan: Plan) => plan.name !== promotedPlan.name
      );
      sortedPlans = [promotedPlan, ...plansWithoutPromoted];
    }
    return sortedPlans;
  }, [plansData, activePlan, isMobile]);

  const {quoteData, isLoading} = useQuoteQuery({
    quoteId: plansCollection.find(
      (planItem: Plan) => planItem.name === TRIAL_TIER_NAME
    )?.griffinQuoteId,
  });

  useEffect(() => {
    handleScreenPresentedEvent({
      screenName: screenNames.PRICING.PRICING_PAGE,
    });
  }, []);

  if (!decisions['pricing-updates']?.enabled) {
    raiseFlashMessage({
      status: 'error',
      message: 'You are not authorized to view this page',
    });
    navigate(ROUTES.HOME);
    return null;
  }

  if (!subscription.loaded) return <PoppaysLoader />;

  const onCTAClick = (planName: string) => {
    handleSubscriptionButtonPressedEvent({
      screenName: screenNames.PRICING.PRICING_PAGE,
      buttonName: buttonNames.SUBSCRIPTION_MODAL.SUBSCRIBE,
    });
    handleScreenDismissedEvent({
      screenName: screenNames.PRICING.PRICING_PAGE,
      dismissedReason: dismissedReasons.SUBSCRIPTION_MODAL.CONTINUE,
    });
    modalSubscriptionActions.open({
      planName,
      state: 'payment',
      eventData: {isNewFlowStarted: false},
    });
  };
  const onContactSalesClick = async () => {
    handleSubscriptionButtonPressedEvent({
      screenName: screenNames.PRICING.PRICING_PAGE,
      buttonName: buttonNames.SUBSCRIPTION_MODAL.CONTACT_SALES,
    });
    await handleContactSalesClick(subscription.id);
    handleScreenDismissedEvent({
      screenName: screenNames.PRICING.PRICING_PAGE,
      dismissedReason: dismissedReasons.SUBSCRIPTION_MODAL.CONTINUE,
    });
    modalSubscriptionActions.open({
      planName: DEFAULT_PLAN_NAME,
      state: 'result',
      eventData: {isNewFlowStarted: false},
    });
  };

  return (
    <div className={styles.pricingPage}>
      {contactSalesRequestLoading && <PoppaysLoader />}
      <div className={styles.topBar}>
        <Button
          appearance="neutral"
          mode="plain"
          size="medium"
          className={styles.backButton}
          icon={
            <Icon
              size="medium"
              appearance="neutral"
              name="Actions-Arrow-Back-Small"
            />
          }
          onClick={handleBackClick}
        >
          {translate('Back')}
        </Button>
        <Link to={ROUTES.HOME}>
          <MenuPopularPaysLogo height={32} className={styles.PPLogo} />
        </Link>
      </div>
      <Headline size="lg" className={styles.title}>
        {translate(`${TRANSLATION_PREFIX}.header`)}
      </Headline>
      <div className={styles.pricingTiers}>
        {plansCollection?.map((plan: Plan) => (
          <PricingTier
            key={plan.id}
            id={plan.id}
            name={plan.label}
            description={plan.description}
            price={plan.cost}
            currency={CURRENCY}
            priceFootnote={plan.priceFootnote}
            featuresHeader={plan.marketingFeatureListHeading}
            features={plan.marketingFeatureList}
            isTrialTier={plan.name === TRIAL_TIER_NAME}
            isActiveTier={plan.name === activePlan.name}
            onCTAClick={() => onCTAClick(plan.name)}
            onContactSalesClick={onContactSalesClick}
            paymentType={plan.paymentType}
            planDurationInDays={
              !isLoading
                ? convertDurationToDays(
                    quoteData?.properties?.vendorIntroductoryOffer?.adyencc?.[0]
                      ?.duration
                  )
                : null
            }
            hideSubscriptionButton={hideSubscriptionButton(
              plan.paymentType,
              plan.id,
              activePlan?.id
            )}
            hideContactSalesButton={hideContactSalesButton(
              plan.paymentType,
              plan.id,
              activePlan?.id
            )}
          />
        ))}
      </div>
      <div className={styles.logoMarqueeSection}>
        <Headline size="lg">
          {translate(`${TRANSLATION_PREFIX}.marquee-section.title`)}
        </Headline>
        <LogoMarquee />
      </div>
    </div>
  );
}

export default PricingPage;
