import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

/**
 * clears unwanted search params from the URL
 *
 * Usage: useWhitelistedSearchParams(['filter', 'type']);
 *
 * @param whitelistedParams - array of whitelisted search params
 * @param dependencies - optional array of dependencies to re-trigger the effect
 */
function useWhitelistedSearchParams(
  whitelistedParams: string[],
  dependencies: unknown[] = []
) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (!whitelistedParams.includes(key)) {
        searchParams.delete(key);
      }
    });
    setSearchParams(searchParams);
  }, [whitelistedParams, ...dependencies]);
}

export default useWhitelistedSearchParams;
