import clsx from 'clsx';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import AuthRoutes from '@/routes/auth';
import MAIN_ROUTES_LIST from '@/routes/main/mainRoutesList';
import {
  HIDE_MENU_ROUTES,
  HIDE_BRAND_HEADER_ROUTES,
  HIDE_SUBSCRIPTION_BANNER_ROUTES,
} from '@/config/routesConstants';
import CampaignIsLiveModal from '@/views/campaigns/brief/components/campaign-brief/components/campaign-is-live-modal/CampaignIsLiveModal';
import Banner from '@/components/banner';
import BrandHeader from '@/components/brand-header';
import EmberIframe from '@/components/ember-iframe';
import Menu from '@/components/menu';
import SubscriptionExpirationBanner from '@/components/subscription-expiration-banner';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useElementHeight from '@/hooks/use-element-height';
import useIsMobile from '@/hooks/use-is-mobile';
import useNavigation from '@/hooks/use-navigation';
import useSetEmberIframeStyles from '@/hooks/use-set-ember-iframe-styles';
import useToken from '@/hooks/use-token';
import useEmberIframeStyleStore, {
  emberIframeStyleActions,
} from '@/stores/emberIframeStyleStore';
import useRedirectAfterAuthStore, {
  redirectAfterAuthActions,
} from '@/stores/redirectAfterAuthStore';
import styles from './MainRoutes.module.scss';

export type MainRoutesProps = {
  decisions: any;
};

function MainRoutes(props: MainRoutesProps) {
  const {decisions} = props;
  const isMobile = useIsMobile();
  const {isAuthenticated} = useToken(true);
  const location = useLocation();
  const brandId = useBrandId();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [emberModalsStack, setEmberModalsStack] = useState<string[]>([]);
  const [headerBannerShown, setHeaderBannerShown] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const {headerHeight: initialHeaderHeight, menuHeight: initialMenuHeight} =
    useEmberIframeStyleStore();

  const navigation = useNavigation();

  const {redirectUrlAfterAuth} = useRedirectAfterAuthStore();

  useEffect(() => {
    if (isAuthenticated && redirectUrlAfterAuth) {
      navigation.navigate(redirectUrlAfterAuth);
      redirectAfterAuthActions.setRedirectUrlAfterAuth(null);
    }
  }, [isAuthenticated, navigation, redirectUrlAfterAuth]);

  const {dashboardUser}: any = useDashboardUserQuery({
    enabled: isAuthenticated,
    overrideEnabled: !brandId && isAuthenticated,
  });

  const testRouteLocation = useCallback(
    (route: string) => new RegExp(route, 'i').test(location.pathname),
    [location.pathname]
  );

  const {subscription} = useSubscriptionQuery({
    brandId,
  });

  const {elementRef: headerRef, elementHeight: headerHeight} = useElementHeight(
    [subscription],
    initialHeaderHeight
  );
  const {elementRef: menuRef, elementHeight: menuHeight} = useElementHeight(
    [subscription, isMobile],
    initialMenuHeight
  );

  const sidebarWidth = useMemo(() => {
    return parseInt(
      isMenuCollapsed ? styles.sidenavCollapsedWidth : styles.sidenavFullWidth,
      10
    );
  }, [isMenuCollapsed]);

  useEffect(() => {
    emberIframeStyleActions.setDependantDimensions({
      headerHeight,
      menuHeight,
      sidebarWidth,
    });
  }, [headerHeight, menuHeight, sidebarWidth]);

  const emberIframeRef = useRef<HTMLIFrameElement>(null);

  const getZIndexClass = () => {
    if (emberModalsStack.length) {
      return styles.zeroIndex;
    }
    return '';
  };

  useSetEmberIframeStyles(emberIframeRef, iframeLoaded, location);

  const {showBrandHeader, showMenu, showSubscriptionExpirationBanner} =
    useMemo(() => {
      return {
        showBrandHeader:
          isAuthenticated && !HIDE_BRAND_HEADER_ROUTES.find(testRouteLocation),
        showMenu: isAuthenticated && !HIDE_MENU_ROUTES.find(testRouteLocation),
        showSubscriptionExpirationBanner:
          isAuthenticated &&
          !HIDE_SUBSCRIPTION_BANNER_ROUTES.find(testRouteLocation),
      };
    }, [isAuthenticated, testRouteLocation]);

  useEffect(() => {
    const removeModalListener = EmberIframe.listen(
      'modal',
      (modalNameOrFalse) => {
        setEmberModalsStack((previousEmberModalsStack) =>
          modalNameOrFalse
            ? [...previousEmberModalsStack, modalNameOrFalse]
            : previousEmberModalsStack.slice(0, -1)
        );
      }
    );
    return removeModalListener;
  }, []);

  const menuContainerClassNames = clsx(
    styles.menuContainer,
    getZIndexClass(),
    headerBannerShown && styles.headerBannerShown
  );

  const renderMenu = () =>
    showMenu ? (
      <div className={menuContainerClassNames}>
        <Menu
          user={dashboardUser}
          decisions={decisions}
          subscription={subscription}
          isCollapsed={isMenuCollapsed}
          handleCollapse={() => setIsMenuCollapsed(!isMenuCollapsed)}
          ref={menuRef}
        />
      </div>
    ) : null;

  const renderHeader = () => (
    <div className={`${styles.headerContainer} ${getZIndexClass()}`}>
      <div className={styles.headerWrapper} ref={headerRef}>
        {showBrandHeader ? (
          <BrandHeader user={dashboardUser} subscription={subscription} />
        ) : null}
        {showSubscriptionExpirationBanner ? (
          <SubscriptionExpirationBanner
            subscription={subscription}
            setHeaderBannerShown={setHeaderBannerShown}
          />
        ) : null}
      </div>
    </div>
  );

  const contentContainerClassNames = clsx(
    styles.contentContainer,
    !showMenu && styles.hideMenu,
    headerBannerShown && styles.headerBannerShown
  );

  const routes = MAIN_ROUTES_LIST.filter(({name}) => {
    // override flag decision
    return true;
    const decisionNotExists = !(name in decisions);
    return decisionNotExists || decisions[name].enabled;
  });

  const renderContent = () => (
    <div className={contentContainerClassNames}>
      <Routes>
        {AuthRoutes()}
        <Route
          path="/*"
          element={
            <EmberIframe
              iframeRef={emberIframeRef}
              setIframeLoaded={setIframeLoaded}
            />
          }
        />
        {routes.map(({name, path, Component}) => (
          <Route
            key={name}
            path={path}
            element={
              <Component decisions={decisions} dashboardUser={dashboardUser} />
            }
          />
        ))}
      </Routes>
    </div>
  );

  const appContainerClassNames = clsx(
    styles.appContainer,
    !emberIframeRef.current && styles.reactRouteContainer,
    !showMenu && styles.fullscreen
  );

  return (
    <div className={styles.appWrapper}>
      <Banner />
      <CampaignIsLiveModal />
      <div className={styles.container}>
        <div className={appContainerClassNames}>
          {renderHeader()}
          {renderMenu()}
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

export default MainRoutes;
