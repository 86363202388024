import React, {useMemo} from 'react';
import translate from '@/utils/translate';
import {ViewType} from '@/types/models/search-creators/searchCreatorsStore';
import {Sorting} from '@/types/models/search-creators/sorting';
import ActionDrawerButtons from '@/views/creators/components/action-drawer-buttons';
import getAnalyticsSearchScreenName from '@/views/creators/search/utils/getAnalyticsSearchScreenName';
import DropdownButton from '@/components/dropdown-button';
import TotalResults from '@/components/total-results';
import ViewTypeSelector from '@/components/view-type-selector';
import useTrackSearchButtonEvent from '@/hooks/analytics/use-track-search-button-event';
import {
  searchCreatorsActions,
  VIEW_TYPE_GRID,
  VIEW_TYPE_TABLE,
} from '@/stores/search-creators/searchCreatorsStore';
import styles from './SearchControls.module.scss';

const TRANSLATION_PREFIX = 'views.creators.search.components.search-controls';

type SearchControlsProps = {
  totalResults: number;
  searchQuery?: string;
  viewType: ViewType;
  onActionDrawerButtonClick?: (label: string) => void;
  sorting?: Sorting;
};

const gridIcon = '/assets/svg/grid.svg';
const listIcon = '/assets/svg/list.svg';

const VIEW_TYPES = [
  {
    id: 'grid',
    icon: gridIcon,
  },
  {
    id: 'table',
    icon: listIcon,
  },
];

const SORT_TYPES = [
  {
    id: 'relevancy',
    labelLocaleKey: `${TRANSLATION_PREFIX}.sorting.options.relevancy`,
  },
  {
    id: 'rating',
    labelLocaleKey: `${TRANSLATION_PREFIX}.sorting.options.rating`,
  },
  {
    id: 'engagementRate',
    labelLocaleKey: `${TRANSLATION_PREFIX}.sorting.options.engagement-rate`,
  },
  {
    id: 'followers',
    labelLocaleKey: `${TRANSLATION_PREFIX}.sorting.options.followers`,
  },
];

const analyticsButtonName = {
  [VIEW_TYPE_TABLE]: 'cards-view',
  [VIEW_TYPE_GRID]: 'list-view',
};

function SearchControls({
  totalResults,
  searchQuery,
  viewType,
  onActionDrawerButtonClick,
  sorting,
}: SearchControlsProps) {
  const trackSearchButtonEvent = useTrackSearchButtonEvent();
  const sortByLabel = useMemo(() => {
    const baseLabel = translate(`${TRANSLATION_PREFIX}.sorting.label`);

    if (sorting) {
      const selectedSortType = SORT_TYPES.find(
        (sortType) => sortType.id === sorting.method
      );
      if (selectedSortType) {
        return `${baseLabel}: ${translate(selectedSortType.labelLocaleKey)}`;
      }
    }
    return baseLabel;
  }, [sorting]);

  return (
    <div className={styles.searchControls}>
      <div className={styles.left}>
        <ActionDrawerButtons
          onActionDrawerButtonClick={onActionDrawerButtonClick}
        />
      </div>
      <div className={styles.right}>
        <TotalResults amount={totalResults} searchTerm={searchQuery} />
        <DropdownButton
          label={sortByLabel}
          options={SORT_TYPES.map((sortType) => ({
            ...sortType,
            label: translate(sortType.labelLocaleKey),
          }))}
          selectedOptionId={sorting ? sorting.method : undefined}
          onChange={(option) => {
            if (option.id !== sorting?.method) {
              trackSearchButtonEvent.pressed({
                button_name: `Sort By: ${option.label}`,
                screen_name: getAnalyticsSearchScreenName(viewType),
              });
              searchCreatorsActions.changeSorting(option.id as string, 'DESC');
            }
          }}
        />
        <ViewTypeSelector
          viewTypes={VIEW_TYPES}
          selectedViewTypeId={viewType}
          onChange={(viewTypeId) => {
            trackSearchButtonEvent.pressed({
              button_name: analyticsButtonName[viewType],
              screen_name: getAnalyticsSearchScreenName(viewType),
            });
            searchCreatorsActions.changeViewType(viewTypeId as ViewType);
          }}
        />
      </div>
    </div>
  );
}

export default SearchControls;
