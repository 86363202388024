import {isArray, isEmpty} from 'lodash';
import {
  AudienceAgeSelection,
  BaseFilterOption,
  DynamicPlatformFilters,
  Filter,
  FiltersGroup,
  MultiSelection,
  MultiUserInput,
  RangeSelection,
  SingleSelection,
  SingleUserInput,
  WeightedFilterOption,
  WeightedMultiSelection,
  WeightedSingleSelection,
} from '@/types/models/search-creators/filter';
import {
  BasePlatformFilterId,
  CreatorDetailsFilterId,
  FilterIds,
  FiltersGroupId,
  KeywordsFilterId,
  PlatformFilterId,
  PlatformFilterIds,
  PlatformId,
  TiktokFilterId,
  YoutubeFilterId,
} from '@/types/models/search-creators/filterId';
import {
  AgeRange,
  AudienceFilter,
  BasePlatformFilter,
  Gender,
  InstagramFilter,
  IntRange,
  LocationValue,
  MultiPlatformFilter,
  PlatformFilters,
  ProfileFilter,
  SortField,
  Sorting,
  TiktokFilter,
  WeightedFilter,
  YoutubeFilter,
} from '@/types/models/search-creators/searchCreators';
import SortMethod from '@/types/models/search-creators/sortMethod';
import {
  SortDirection as StateSortVariant,
  Sorting as StateSorting,
} from '@/types/models/search-creators/sorting';

export function mapToSorting(
  sorting: StateSorting | undefined
): Sorting | undefined {
  if (!sorting) {
    return undefined;
  }

  return {
    field: mapSortMethodToSortBy(sorting.method),
    ascending: mapToIsAscending(sorting.direction),
  };
}

export function mapToMultiplatformFilter(
  searchQuery: string | undefined,
  filterGroups: Record<FiltersGroupId, FiltersGroup<FilterIds>>
): MultiPlatformFilter {
  const {creatorDetails, keywords, platforms} = filterGroups;
  const creatorDetailsFiltersGroup =
    creatorDetails as FiltersGroup<CreatorDetailsFilterId>;
  const keywordsFiltersGroup = keywords as FiltersGroup<KeywordsFilterId>;
  const platformsFiltersGroup = platforms as FiltersGroup<PlatformFilterId>;
  const multiPlatformFilter: MultiPlatformFilter = {
    profile: mapToProfileFilter(
      creatorDetailsFiltersGroup,
      keywordsFiltersGroup
    ),
    audience: mapToAudienceFilter(platformsFiltersGroup),
    platforms: mapToPlatformFilters(platformsFiltersGroup),
  };

  if (searchQuery) {
    multiPlatformFilter.compound = [searchQuery];
  }

  return multiPlatformFilter;
}

type AppliedFilters = Partial<Record<FilterIds, unknown>>;
type AppliedFiltersGroups = Partial<Record<FiltersGroupId, AppliedFilters>>;

export function mapToAppliedFilters(
  filterGroups: Record<FiltersGroupId, FiltersGroup<FilterIds>>
): AppliedFiltersGroups | undefined {
  const appliedFiltersGroups: AppliedFiltersGroups = {};

  function getSelected(filter: Filter): unknown | undefined {
    switch (filter.type) {
      case 'singleSelection': {
        const {selected} = filter as SingleSelection<BaseFilterOption>;
        if (filter.asyncInitializationKey) {
          return selected;
        }
        return selected?.value ? selected.id : undefined;
      }
      case 'weightedSingleSelection': {
        const {selected} = filter as SingleSelection<WeightedFilterOption>;
        if (filter.asyncInitializationKey) {
          return selected;
        }
        return selected?.value ? [selected.id, selected.weight] : undefined;
      }
      case 'multiSelection': {
        const {selected} = filter as MultiSelection<BaseFilterOption>;
        if (filter.asyncInitializationKey && selected.length) {
          return selected;
        }
        if (selected.length) {
          return selected.map((option) => option.id);
        }
        return undefined;
      }
      case 'weightedMultiSelection': {
        const {selected} = filter as MultiSelection<WeightedFilterOption>;
        if (filter.asyncInitializationKey && selected.length) {
          return selected;
        }
        if (selected.length) {
          return selected.map((option) => [option.value, option.weight]);
        }
        return undefined;
      }
      case 'multiSelectionAsync': {
        if ((filter as MultiSelection<WeightedFilterOption>).selected.length) {
          return (filter as MultiSelection<WeightedFilterOption>).selected;
        }
        return undefined;
      }
      case 'audienceAge':
        return (
          (filter as AudienceAgeSelection).ldaSelected ||
          ((filter as AudienceAgeSelection).selected.length
            ? (filter as AudienceAgeSelection).selected.map((option) => [
                option.id,
                option.weight,
              ])
            : undefined)
        );
      case 'rangeSelection':
        return (filter as RangeSelection).selected;
      case 'multiUserInput':
        return (filter as MultiUserInput).inputs.length
          ? (filter as MultiUserInput).inputs
          : undefined;
      case 'singleUserInput':
        return (filter as SingleUserInput).input
          ? (filter as SingleUserInput).input
          : undefined;
      case 'dynamicPlatformFilters': {
        const dpf = filter as DynamicPlatformFilters;
        const selectedPlatformFilter = dpf.platformsFilters[dpf.platformId];
        const appliedPlatformFilters: Partial<Record<string, unknown>> = {};
        Object.entries<Filter>(selectedPlatformFilter).forEach(
          ([platformFilterId, platformFilter]) => {
            const selected = getSelected(platformFilter);
            if (selected) {
              appliedPlatformFilters[platformFilterId] = selected;
            }
          }
        );
        const ensuredAppliedPlatformFilters = ensureValuesExist(
          appliedPlatformFilters
        );
        return ensuredAppliedPlatformFilters
          ? [dpf.platformId, ensuredAppliedPlatformFilters]
          : undefined;
      }
      default:
        return undefined;
    }
  }

  Object.entries(filterGroups).forEach(([groupId, group]) => {
    const appliedFilters: AppliedFilters = {};
    Object.entries(group.filters).forEach(([filterId, filter]) => {
      const selected = getSelected(filter);
      if (selected) {
        appliedFilters[filterId as FilterIds] = selected;
      }
    });
    const ensuredAppliedFilters = ensureValuesExist(appliedFilters);
    if (ensuredAppliedFilters) {
      appliedFiltersGroups[groupId as FiltersGroupId] = ensuredAppliedFilters;
    }
  });
  return ensureValuesExist(appliedFiltersGroups);
}

function mapToBasePlatformFilter(
  platformFilters: Partial<Record<PlatformFilterIds, Filter>>
): BasePlatformFilter {
  const basePlatformFilter: BasePlatformFilter = {};
  const stateBasePlatformFilters = platformFilters as Record<
    BasePlatformFilterId,
    Filter
  >;
  if (stateBasePlatformFilters.followers.type === 'rangeSelection') {
    const followers = mapToType<IntRange>(stateBasePlatformFilters.followers);
    if (!isArray(followers)) {
      basePlatformFilter.followers = followers;
    }
  }
  if (stateBasePlatformFilters.engagementRate.type === 'singleSelection') {
    const engagementRate = mapToType<number>(
      stateBasePlatformFilters.engagementRate
    );
    if (!isArray(engagementRate)) {
      basePlatformFilter.engagement_rate = engagementRate;
    }
  }
  return basePlatformFilter;
}

function mapToInstagramFilter(
  platformFilters: Partial<Record<PlatformFilterIds, Filter>>
): InstagramFilter {
  return mapToBasePlatformFilter(platformFilters);
}

function mapToTiktokFilter(
  platformFilters: Partial<Record<PlatformFilterIds, Filter>>
): TiktokFilter {
  const basePlatformFilter: BasePlatformFilter | undefined =
    mapToBasePlatformFilter(platformFilters);
  const tiktokFilter: TiktokFilter = {
    ...basePlatformFilter,
  };
  const stateTiktokFilters = platformFilters as Record<TiktokFilterId, Filter>;
  if (stateTiktokFilters.averageViews.type === 'rangeSelection') {
    const viewsCount = mapToType<IntRange>(stateTiktokFilters.averageViews);
    if (!isArray(viewsCount)) {
      tiktokFilter.views_count = viewsCount;
    }
  }
  return tiktokFilter;
}

function mapToYoutubeFilter(
  platformFilters: Partial<Record<PlatformFilterIds, Filter>>
): YoutubeFilter {
  const basePlatformFilter: BasePlatformFilter | undefined =
    mapToBasePlatformFilter(platformFilters);
  const youTubeFilter: YoutubeFilter = {
    ...basePlatformFilter,
  };
  const stateYoutubeFilters = platformFilters as Record<
    YoutubeFilterId,
    Filter
  >;
  if (stateYoutubeFilters.averageViews.type === 'rangeSelection') {
    const viewsCount = mapToType<IntRange>(stateYoutubeFilters.averageViews);
    if (!isArray(viewsCount)) {
      youTubeFilter.views_count = viewsCount;
    }
  }
  return youTubeFilter;
}

export function mapToPlatformFilters(
  platformFiltersGroup: FiltersGroup<PlatformFilterId>
): PlatformFilters | undefined {
  const dynamicPlatformFilters = platformFiltersGroup.filters
    .platformMetrics as DynamicPlatformFilters;
  const {platformId} = dynamicPlatformFilters;
  const statePlatformFilters =
    dynamicPlatformFilters.platformsFilters[platformId];
  const resultPlatformFilters: PlatformFilters = {};

  switch (platformId) {
    case 'instagram': {
      resultPlatformFilters[platformId] =
        mapToInstagramFilter(statePlatformFilters);
      break;
    }
    case 'tiktok': {
      resultPlatformFilters[platformId] =
        mapToTiktokFilter(statePlatformFilters);
      break;
    }
    case 'youtube': {
      resultPlatformFilters[platformId] =
        mapToYoutubeFilter(statePlatformFilters);
      break;
    }
    case 'facebook':
    case 'twitter':
    case 'pinterest': {
      resultPlatformFilters[platformId] =
        mapToBasePlatformFilter(statePlatformFilters);
      break;
    }
    case 'all': {
      // When all is selected, we need to apply the same filters to all platforms
      const basePlatformFilter = ensureValuesExist(
        mapToBasePlatformFilter(statePlatformFilters)
      );
      if (basePlatformFilter) {
        [
          'instagram',
          'tiktok',
          'youtube',
          'facebook',
          'twitter',
          'pinterest',
        ].forEach((id) => {
          resultPlatformFilters[id as PlatformId] = basePlatformFilter;
        });
      }
      break;
    }
    default:
      throw new Error(`Unknown platformId: ${platformId}`);
  }

  return ensureValuesExist(resultPlatformFilters);
}

function mapToWeightedFilter<T>(
  filter: WeightedSingleSelection<WeightedFilterOption>
): WeightedFilter<T> | undefined {
  if (filter.selected?.value) {
    return {
      value: filter.selected.value as T,
      weight: filter.selected.weight,
    };
  }
  return undefined;
}

function mapToWeightedFilters<T>(
  filter: WeightedMultiSelection<WeightedFilterOption> | AudienceAgeSelection
): WeightedFilter<T>[] | undefined {
  if (!isEmpty(filter.selected)) {
    return filter.selected.map((option) => ({
      value: option.value as T,
      weight: option.weight,
    }));
  }
  return undefined;
}

export function mapToProfileFilter(
  profileFiltersGroup: FiltersGroup<CreatorDetailsFilterId>,
  keywordsFiltersGroup: FiltersGroup<KeywordsFilterId>
): ProfileFilter | undefined {
  const profileFilter: ProfileFilter = {};
  const stateProfileFilters = profileFiltersGroup.filters as Record<
    CreatorDetailsFilterId,
    Filter
  >;
  const stateKeywordFilters = keywordsFiltersGroup.filters as Record<
    KeywordsFilterId,
    Filter
  >;
  if (stateProfileFilters.age.type === 'rangeSelection') {
    const selectedRange = (stateProfileFilters.age as RangeSelection).selected;
    if (selectedRange) {
      const age = {
        from_age: selectedRange.min,
        to_age: selectedRange.max,
      };
      profileFilter.age = age as AgeRange;
    }
  }
  if (stateProfileFilters.gender.type === 'singleSelection') {
    const gender = mapToType<Gender>(stateProfileFilters.gender);
    if (!isArray(gender)) {
      profileFilter.gender = gender;
    }
  }
  if (stateProfileFilters.category.type === 'multiSelection') {
    const categories = mapToType<string>(stateProfileFilters.category);
    if (isArray(categories)) {
      profileFilter.categories = categories;
    }
  }
  if (stateProfileFilters.location.type === 'multiSelectionAsync') {
    const locations = mapToType<LocationValue>(stateProfileFilters.location);
    if (isArray(locations)) {
      profileFilter.locations = locations;
    }
  }
  if (stateProfileFilters.searchBio.type === 'singleUserInput') {
    const searchBio = mapToType<string>(
      stateProfileFilters.searchBio
    ) as string;
    if (searchBio) {
      profileFilter.bio = [searchBio];
    }
  }
  if (stateKeywordFilters.keyword.type === 'multiUserInput') {
    const captions = mapToType<string>(stateKeywordFilters.keyword);
    if (isArray(captions)) {
      profileFilter.captions = captions;
    }
  }

  return ensureValuesExist(profileFilter);
}

export function mapToAudienceFilter(
  platformFiltersGroup: FiltersGroup<PlatformFilterId>
): AudienceFilter | undefined {
  const audienceFilter: AudienceFilter = {};
  const statePlatformFilters = platformFiltersGroup.filters as Record<
    PlatformFilterId,
    Filter
  >;

  if (
    !statePlatformFilters.location.isIrrelevant &&
    statePlatformFilters.location.type === 'multiSelectionAsync'
  ) {
    const locations = mapToType<LocationValue>(statePlatformFilters.location);
    if (isArray(locations)) {
      audienceFilter.locations = locations;
    }
  }
  if (
    !statePlatformFilters.gender.isIrrelevant &&
    statePlatformFilters.gender.type === 'weightedSingleSelection'
  ) {
    audienceFilter.gender = mapToWeightedFilter<Omit<Gender, 'other'>>(
      statePlatformFilters.gender as WeightedSingleSelection<WeightedFilterOption>
    );
  }
  if (
    !statePlatformFilters.age.isIrrelevant &&
    statePlatformFilters.age.type === 'audienceAge'
  ) {
    const audienceAgeFilter = statePlatformFilters.age as AudienceAgeSelection;
    if (audienceAgeFilter.ldaSelected) {
      audienceFilter.is_lda_compliant = audienceAgeFilter.ldaSelected;
    } else {
      audienceFilter.ages = mapToWeightedFilters<AgeRange>(audienceAgeFilter);
    }
  }
  return ensureValuesExist(audienceFilter);
}

function mapSortMethodToSortBy(sortMethod: SortMethod): SortField {
  switch (sortMethod) {
    case SortMethod.engagementRate:
      return 'engagementRate';
    case SortMethod.followers:
      return 'followers';
    case SortMethod.relevancy:
      return 'relevancy';
    case SortMethod.rating:
      return 'rating';
    default:
      throw new Error('Trying to map not-sortable column');
  }
}

function mapToIsAscending(sortVariant: StateSortVariant): boolean {
  return sortVariant === 'ASC';
}

function ensureValuesExist<T extends object>(object: T): T | undefined {
  if (Object.values(object).some((value) => value !== undefined)) {
    return object;
  }
  return undefined;
}

function mapToType<T>(filter: Filter): T | T[] | undefined {
  switch (filter.type) {
    case 'singleSelection': {
      const singleSelection = filter as SingleSelection<BaseFilterOption>;
      if (singleSelection.selected && singleSelection.selected.value) {
        return singleSelection.selected.value as T;
      }
      return undefined;
    }
    case 'multiSelectionAsync':
    case 'multiSelection': {
      const multiSelection = filter as MultiSelection<BaseFilterOption>;
      if (!isEmpty(multiSelection.selected)) {
        return multiSelection.selected.map((option) => option.value as T);
      }
      return undefined;
    }
    case 'multiUserInput': {
      const multiUserInput = filter as MultiUserInput;
      if (!isEmpty(multiUserInput.inputs)) {
        return multiUserInput.inputs as T[];
      }
      return undefined;
    }
    case 'singleUserInput': {
      const singleUserInput = filter as SingleUserInput;
      if (singleUserInput.input) {
        return singleUserInput.input as T;
      }
      return undefined;
    }
    case 'rangeSelection': {
      const rangeSelection = filter as RangeSelection;
      if (rangeSelection.selected) {
        return rangeSelection.selected as T;
      }
      return undefined;
    }
    default:
      return undefined;
  }
}
