import {createInstance} from '@optimizely/react-sdk';
import {getEnv} from '@/config/environment';

const optimizelyClient = createInstance({
  sdkKey: getEnv().VITE_OPTIMIZELY_SDK_KEY,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 1000, // remove before deployment to production - default value is 5 minutes
  },
});

export default optimizelyClient;
