import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {RouteProps} from '@/routes/RouteProps';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import {EVENT_NAMES, FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import AiContentGenerator from '@/views/ai-content-generator';
import Dashboard from '@/views/ai-content-generator/generated-content/dashboard';
import Welcome from '@/views/ai-content-generator/welcome';

export const AI_STUDIO_ROUTES = {
  BASE: '/ai-studio',
  MAIN: '',
  DASHBOARD: 'generated-content/dashboard',
};

function AiContentGeneratorRoutes(props: RouteProps) {
  const {decisions} = props;
  const navigate = useNavigate();

  useEffect(() => {
    let aiStudioFlow = AnalyticsService.getFlow(FLOW_NAMES.AI_STUDIO);
    if (!aiStudioFlow) {
      aiStudioFlow = AnalyticsService.createFlow(FLOW_NAMES.AI_STUDIO);
      AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ENTERED, {
        flow_id: aiStudioFlow.flow_id,
        start_source: 'external_url',
        source_text: null,
      });
    }

    return () => {
      AnalyticsService.endFlow(FLOW_NAMES.AI_STUDIO);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(decisions).length && !decisions['ai-studio'].enabled) {
      navigate('/');
      // in the future we can add a message to the user that they don't have access to this feature
    }
  }, [decisions, navigate]);

  if (Object.keys(decisions).length && !decisions['ai-studio'].enabled) {
    return null;
  }

  return (
    <Routes>
      <Route
        key="ai-studio-welcome"
        path={AI_STUDIO_ROUTES.MAIN}
        element={<Welcome />}
      />
      <Route
        key="ai-studio"
        path={AI_STUDIO_ROUTES.MAIN}
        element={<AiContentGenerator />}
      >
        <Route path={AI_STUDIO_ROUTES.DASHBOARD} element={<Dashboard />} />
      </Route>
    </Routes>
  );
}

export default AiContentGeneratorRoutes;
