import React, {useMemo, useState} from 'react';
import {Box, Menu, MenuItem} from '@mui/material';
import {
  Button,
  designSystemToken,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import styles from './DropdownButton.module.scss';

export interface Option {
  id: string;
  label: string;
  disabled?: boolean;
}

export interface DropdownButtonProps {
  testID?: string;
  label: string;
  options: Option[];
  selectedOptionId?: string;
  onChange: (option: Option) => void;
  disabled?: boolean;
}

function DropdownButton(props: DropdownButtonProps) {
  const {
    testID = 'dropdown-button',
    label,
    options,
    selectedOptionId,
    onChange,
    disabled,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const buttonStyle = useMemo(() => {
    let borderColor = 'semantic.bg.neutral-inverse';
    if (disabled) {
      borderColor = 'semantic.fg.disabled';
    }

    return {
      border: `1px solid ${designSystemToken(borderColor)}`,
    };
  }, [disabled]);

  const labelStyle = useMemo(() => {
    let color = 'semantic.fg.primary';
    if (disabled) {
      color = 'semantic.fg.disabled';
    }

    return {
      color: designSystemToken(color),
    };
  }, [disabled]);

  const renderLabel = () => (
    <Box className={styles.labelContainer}>
      <Label size="md" sx={labelStyle}>
        {label}
      </Label>
      <Icon
        className={anchorEl ? styles.iconOpen : ''}
        size="small"
        appearance="neutral"
        name="Actions-Arrow-Down-Small"
        color={designSystemToken('semantic.fg.primary')}
      />
    </Box>
  );

  const renderMenu = () => (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '24px',
          paddingInline: '8px',
          minWidth: '150px',
        },
        '& .MuiMenu-list': {
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id}
          onClick={() => {
            onChange(option);
            setAnchorEl(null);
          }}
          disabled={option.disabled}
          sx={{
            'display': 'flex',
            'padding': '16px 12px 16px 12px',
            'borderRadius': '16px',
            'background':
              selectedOptionId === option.id
                ? designSystemToken('semantic.bg.neutral-inverse')
                : 'transparent',
            '&:hover': {
              background: designSystemToken('semantic.bg.tertiary'),
            },
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <Button
        testID={testID}
        size="small"
        mode="plain"
        appearance="neutral"
        disabled={disabled}
        onClick={handleOnClick}
        sx={buttonStyle}
      >
        {renderLabel()}
      </Button>
      {renderMenu()}
    </>
  );
}

export default DropdownButton;
