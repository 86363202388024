import {setLogLevel} from '@optimizely/react-sdk';
import {useEffect, useState} from 'react';
import optimizelyClient from '@/services/optimizely/Optimizely';
import getCookieValue from '@/utils/getCookieValue';
import {getEnv} from '@/config/environment';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import useToken from '@/hooks/use-token';

const LT_ID_COOKIE_NAME = getEnv().MODE === 'staging' ? 'stg__lt_id' : 'lt_id';

function useSetOptimizelyUser() {
  const [isInitialized, setIsInitialized] = useState(false);
  const {isAuthenticated, initialized: tokenInitialized} = useToken(true);
  const ltId = getCookieValue(LT_ID_COOKIE_NAME);
  const isMobile = useIsMobile();
  const decisions = optimizelyClient.getUserContext()?.decideAll() || {};
  const brandId = useBrandId();
  const {dashboardUser} = useDashboardUserQuery({
    brandId,
    enabled: isAuthenticated && !!brandId,
    forceAddBrandId: true,
    include: ['current_brand'],
  });

  const {subscription} = useSubscriptionQuery({
    brandId,
    enabled: isAuthenticated,
  });

  useEffect(() => {
    setLogLevel('error');
  }, []);

  useEffect(() => {
    if (
      dashboardUser?.loaded &&
      subscription?.loaded &&
      (optimizelyClient.user.id !== dashboardUser.id ||
        optimizelyClient.user.attributes?.brand_id !==
          dashboardUser.currentBrand?.id)
    ) {
      optimizelyClient.setUser({
        id: `${ltId}:${dashboardUser.currentBrand?.id}`,
        attributes: {
          email: dashboardUser.email,
          brand_id: dashboardUser.currentBrand?.id,
          brand_name: dashboardUser.currentBrand?.name,
          is_test_brand: dashboardUser.currentBrand?.isTestBrand,
          is_mobile: isMobile,
          time_zone:
            Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone || '',
          subscription_type: subscription.subscriptionType,
        },
      });

      if (!isInitialized) {
        setIsInitialized(true);
      }
    } else if (!isAuthenticated && optimizelyClient.user.id !== 'anonymous') {
      optimizelyClient.setUser({id: 'anonymous'});
    }

    if (tokenInitialized && !isAuthenticated && !isInitialized) {
      setIsInitialized(true);
    }
  }, [
    dashboardUser?.loaded,
    subscription?.loaded,
    isInitialized,
    tokenInitialized,
    isAuthenticated,
    ltId,
    dashboardUser?.id,
    dashboardUser?.currentBrand?.id,
    dashboardUser?.currentBrand?.name,
    dashboardUser?.currentBrand?.isTestBrand,
    dashboardUser?.email,
    subscription.subscriptionType,
    isMobile,
  ]);

  return {
    decisions,
    optimizelyInitialized: isInitialized,
  };
}

export default useSetOptimizelyUser;
