import {produce} from 'immer';
import {WritableDraft} from 'immer/src/types/types-external';
import {
  AudienceAgeSelection,
  BaseFilterOption,
  DynamicPlatformFilters,
  Filter,
  FiltersGroup,
  MultiSelection,
  MultiSelectionAsync,
  MultiUserInput,
  Range,
  RangeSelection,
  SingleSelection,
  SingleUserInput,
  WeightedFilterOption,
  WeightedMultiSelection,
} from '@/types/models/search-creators/filter';
import {
  FilterIds,
  FiltersGroupId,
  PlatformFilterId,
  PlatformFilterIds,
  PlatformId,
} from '@/types/models/search-creators/filterId';
import {
  SearchCreatorsStateDefinition,
  ViewType,
} from '@/types/models/search-creators/searchCreatorsStore';
import {Sorting as StateSorting} from '@/types/models/search-creators/sorting';
import {mapToAppliedFilters} from '@/api/fetchers/creators/search/utils';

type AppliedFilters = Partial<Record<FilterIds, unknown>>;
type AppliedFiltersGroups = Partial<Record<FiltersGroupId, AppliedFilters>>;

type PersistenceState = {
  viewType: ViewType;
  searchQuery?: string;
  appliedFiltersGroups?: AppliedFiltersGroups;
  sorting?: StateSorting;
  selectedBrandId: string;
  selectedCampaignId?: string;
};

export function mapSearchCreatorsStateToPersistenceState(
  viewType: ViewType,
  searchQuery: string | undefined,
  filterGroups: Record<FiltersGroupId, FiltersGroup<FilterIds>>,
  sorting: StateSorting | undefined,
  selectedBrandId: string,
  selectedCampaignId?: string
): PersistenceState {
  return {
    viewType,
    searchQuery,
    appliedFiltersGroups: mapToAppliedFilters(filterGroups),
    sorting,
    selectedBrandId,
    selectedCampaignId,
  };
}

export function mapPersistenceStateToSearchCreatorsState(
  json: string,
  state: SearchCreatorsStateDefinition
): SearchCreatorsStateDefinition {
  const persistenceState: PersistenceState = JSON.parse(json);

  function selectAppliedFilter(
    filter: WritableDraft<Filter>,
    appliedFilter: unknown
  ) {
    switch (filter.type) {
      case 'singleSelection': {
        const ssf = filter as WritableDraft<SingleSelection<BaseFilterOption>>;
        if (ssf.asyncInitializationKey) {
          ssf.selected = appliedFilter as BaseFilterOption;
        } else {
          const appliedFilterOptionId = appliedFilter as string;
          const selectedOption = ssf.options.find(
            (option) => option.id === appliedFilterOptionId
          );
          if (selectedOption) {
            ssf.selected = selectedOption;
          }
        }
        break;
      }
      case 'weightedSingleSelection': {
        const wssf = filter as WritableDraft<
          SingleSelection<WeightedFilterOption>
        >;
        if (wssf.asyncInitializationKey) {
          wssf.selected = appliedFilter as BaseFilterOption;
        } else {
          const appliedFilterOptionIdAndWeight = appliedFilter as [
            string,
            number | undefined
          ];
          const selectedOption = wssf.options.find(
            (option) => option.id === appliedFilterOptionIdAndWeight[0]
          );
          if (selectedOption) {
            wssf.selected = {
              ...selectedOption,
              weight: appliedFilterOptionIdAndWeight[1],
            };
          }
        }
        break;
      }
      case 'multiSelection': {
        const msf = filter as WritableDraft<MultiSelection<BaseFilterOption>>;
        if (msf.asyncInitializationKey) {
          msf.selected = appliedFilter as BaseFilterOption[];
        } else {
          const appliedFilterOptionIds = appliedFilter as string[];
          msf.selected = msf.options.filter((option) =>
            appliedFilterOptionIds.includes(option.id)
          );
        }
        break;
      }
      case 'multiSelectionAsync': {
        const msa = filter as WritableDraft<
          MultiSelectionAsync<BaseFilterOption>
        >;
        msa.selected = appliedFilter as BaseFilterOption[];
        break;
      }
      case 'weightedMultiSelection': {
        const wmsf = filter as WritableDraft<
          WeightedMultiSelection<WeightedFilterOption>
        >;
        if (wmsf.asyncInitializationKey) {
          wmsf.selected = appliedFilter as WeightedFilterOption[];
        } else {
          const appliedFilterOptionIdsAndWeightsMap = new Map(
            appliedFilter as [string, number | undefined][]
          );
          wmsf.selected = wmsf.options
            .filter((option) =>
              appliedFilterOptionIdsAndWeightsMap.has(option.id)
            )
            .map((option) => ({
              ...option,
              weight: appliedFilterOptionIdsAndWeightsMap.get(option.id),
            }));
        }
        break;
      }
      case 'audienceAge': {
        const aas = filter as WritableDraft<AudienceAgeSelection>;
        if (typeof appliedFilter === 'boolean') {
          aas.ldaSelected = appliedFilter;
        } else {
          const appliedFilterOptionIdsAndWeightsMap = new Map(
            appliedFilter as [string, number | undefined][]
          );
          aas.selected = aas.options
            .filter((option) =>
              appliedFilterOptionIdsAndWeightsMap.has(option.id)
            )
            .map((option) => ({
              ...option,
              weight: appliedFilterOptionIdsAndWeightsMap.get(option.id),
            }));
        }
        break;
      }
      case 'rangeSelection': {
        const rs = filter as WritableDraft<RangeSelection>;
        rs.selected = appliedFilter as Range;
        break;
      }
      case 'multiUserInput': {
        const mui = filter as MultiUserInput;
        mui.inputs = appliedFilter as string[];
        break;
      }
      case 'singleUserInput': {
        const sui = filter as SingleUserInput;
        sui.input = appliedFilter as string;
        break;
      }
      case 'dynamicPlatformFilters': {
        const dpf = filter as WritableDraft<DynamicPlatformFilters>;
        const [platformId, appliedPlatformFilters] = appliedFilter as [
          PlatformId,
          Partial<Record<string, unknown>>
        ];
        const selectedPlatformFilters = dpf.platformsFilters[platformId];
        dpf.platformId = platformId;

        Object.entries(appliedPlatformFilters).forEach(
          ([platformFilterId, appliedPlatformFilter]) => {
            const platformFilter =
              selectedPlatformFilters[platformFilterId as PlatformFilterIds];
            if (!platformFilter) return;
            selectAppliedFilter(platformFilter, appliedPlatformFilter);
          }
        );
        break;
      }
      default:
    }
  }

  return produce(state, (draft) => {
    draft.searchQuery = persistenceState.searchQuery;
    const filterGroups = draft.content.filtersState.filtersGroupsSnapshot;
    const appliedFiltersGroups = persistenceState.appliedFiltersGroups ?? {};
    Object.keys(appliedFiltersGroups).forEach((groupId) => {
      const {filters} = filterGroups[groupId as FiltersGroupId];
      const appliedFilters = appliedFiltersGroups[groupId as FiltersGroupId];
      if (!appliedFilters) return;
      Object.keys(appliedFilters).forEach((filterId) => {
        const filter = filters[filterId as FilterIds];
        const appliedFilter = appliedFilters[filterId as FilterIds];
        if (!filter) return;
        selectAppliedFilter(filter, appliedFilter);
        // handle platform metrics filter platformId
        if (groupId === 'platforms' && filterId === 'platform') {
          const platformMetrics = filterGroups.platforms.filters
            .platformMetrics as WritableDraft<DynamicPlatformFilters>;
          platformMetrics.platformId = appliedFilter as PlatformId;
          const platform = filterGroups.platforms.filters
            .platform as WritableDraft<SingleSelection<BaseFilterOption>>;

          const isIrrelevant = platform.selected?.id !== 'instagram';
          const platformFilters = filterGroups.platforms
            .filters as WritableDraft<Record<PlatformFilterId, Filter>>;
          platformFilters.age.isIrrelevant = isIrrelevant;
          platformFilters.gender.isIrrelevant = isIrrelevant;
          platformFilters.location.isIrrelevant = isIrrelevant;
        }
      });
    });
    draft.viewType = persistenceState.viewType;
    draft.content.filtersState.filtersGroups = filterGroups;
    draft.content.sorting = persistenceState.sorting;
    draft.selectedBrandId = persistenceState.selectedBrandId;
    draft.selectedCampaignId = persistenceState.selectedCampaignId;
  });
}
